import { OnInit } from '@angular/core';
import { CertificateService } from '../../services/certificate.service';
var RecentComponent = /** @class */ (function () {
    function RecentComponent(certificateService) {
        this.certificateService = certificateService;
    }
    RecentComponent.prototype.ngOnInit = function () {
        this.getRecentChains(0);
    };
    RecentComponent.prototype.getRecentChains = function (page) {
        var _this = this;
        this.certificateService.getRecentChains(page).subscribe(function (response) { return _this.recentChains = response; });
    };
    return RecentComponent;
}());
export { RecentComponent };
