<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <button class="navbar-toggler" type="button" (click)="collapsed = !collapsed" [attr.aria-expanded]="!collapsed"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarColor01" [ngbCollapse]="collapsed">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/home">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/search">Certificates</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/trusted">Trusted</a>
      </li>
    </ul>

    <ul class="navbar-nav">
      <li class="nav-item" *ngIf="appConfigService.get('show_fork_banner')">
        <a href="https://github.com/capitaltg/thea">
          <img class="text-right " style="position: absolute;right:0px;top:0px" width="149" height="149"
            src="assets/fork.png" class="attachment-full size-full" alt="Fork me on GitHub">
        </a>
      </li>

      <li class="nav-item" *ngIf="!auth.isAuthenticated() && appConfigService.get('show_login')">
        <a class="nav-link" (click)="auth.login()" routerLink="#">Login</a>
      </li>
      <li class="nav-item" *ngIf="auth.userProfile">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-primary" id="dropdownBasic1"
            ngbDropdownToggle>{{auth.userProfile?.name}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem routerLink="/profile"><a>Profile</a></button>
            <button ngbDropdownItem (click)="auth.logout()"><a>Logout</a></button>
          </div>
        </div>
      </li>
      <li class="nav-item mr-5" *ngIf="appConfigService.get('show_fork_banner')">
      </li>
      <li class="nav-item mr-5" *ngIf="appConfigService.get('show_fork_banner')">
      </li>
      <li class="nav-item mr-5" *ngIf="appConfigService.get('show_fork_banner')">
      </li>
    </ul>
  </div>
</nav>