/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./recent.component";
import * as i4 from "../../services/certificate.service";
var styles_RecentComponent = [];
var RenderType_RecentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecentComponent, data: {} });
export { RenderType_RecentComponent as RenderType_RecentComponent };
function View_RecentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-success"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Success"]))], null, null); }
function View_RecentComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-warning"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Warnings"]))], null, null); }
function View_RecentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [["class", "list-group-item d-flex justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecentComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecentComponent_4)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = i0.ɵinlineInterpolate(1, "/chain/", _v.context.$implicit.certificateChainId, ""); _ck(_v, 2, 0, currVal_2); var currVal_4 = _v.context.$implicit.success; _ck(_v, 5, 0, currVal_4); var currVal_5 = !_v.context.$implicit.success; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.hostname; _ck(_v, 3, 0, currVal_3); }); }
function View_RecentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Recently Inspected Endpoints"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecentComponent_2)), i0.ɵdid(5, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recentChains; _ck(_v, 5, 0, currVal_0); }, null); }
function View_RecentComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "spinner-border text-primary"], ["role", "status"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_RecentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecentComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecentComponent_5)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recentChains; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.recentChains; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RecentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-recent", [], null, null, null, View_RecentComponent_0, RenderType_RecentComponent)), i0.ɵdid(1, 114688, null, 0, i3.RecentComponent, [i4.CertificateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecentComponentNgFactory = i0.ɵccf("app-recent", i3.RecentComponent, View_RecentComponent_Host_0, {}, {}, []);
export { RecentComponentNgFactory as RecentComponentNgFactory };
