/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./about.component";
var styles_AboutComponent = [];
var RenderType_AboutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AboutComponent, data: {} });
export { RenderType_AboutComponent as RenderType_AboutComponent };
export function View_AboutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Certificate Checker"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\nConfiguring certificate and certificate chains for secure communications can be challenging.\nThis is true for static-content web servers, Java-based REST micro-services, and numerous applications that leverage TLS for secure client communications. Applications include reverse\nproxies, Jetty and Tomcat, Apache Kafka, Solr and Elasticsearch, Hadoop clusters, Splunk, Nexus or Artifactory repositories, Jenkins, load balancers, and many, many more. Configuring\nclients of these services can often also be complicated because it is difficult to understand\nwhat certificates the service presents and which certificates should be added to the client's\ntrust store (if the service uses a CA that isn't included in the OS, JRE, or runtime platform).\n"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\nCertificate Checker provides an easy-to-use solution to check certificates, certificate chains,\nand TLS configurations. To run Certificate Checker for publicly-accessible web sites you can go\nto: https://certchecker.app and enter in there a URL to check. You can also run Certificate\nChecker on an internal network to validate or troubleshoot non-public-facing TLS configurations.\n"]))], null, null); }
export function View_AboutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-about", [], null, null, null, View_AboutComponent_0, RenderType_AboutComponent)), i0.ɵdid(1, 114688, null, 0, i1.AboutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AboutComponentNgFactory = i0.ɵccf("app-about", i1.AboutComponent, View_AboutComponent_Host_0, {}, {}, []);
export { AboutComponentNgFactory as AboutComponentNgFactory };
