/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./components/app-navbar/app-navbar.component.ngfactory";
import * as i2 from "./components/app-navbar/app-navbar.component";
import * as i3 from "./services/auth.service";
import * as i4 from "./services/app-config.service";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, i1.View_AppNavbarComponent_0, i1.RenderType_AppNavbarComponent)), i0.ɵdid(1, 114688, null, 0, i2.AppNavbarComponent, [i3.AuthService, i4.AppConfigService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "container mt-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i6.AppComponent, [i3.AuthService, i4.AppConfigService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
