<h2>Certificate Chain History</h2>
<h4>{{hostname}}</h4>

<div *ngIf="historicalChains">
    <div *ngIf="historicalChains?.length > 0; else noResultsMessage">
      <ul class="list-group">
        <li class="list-group-item show active">Date Inspected</li>
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let histChain of historicalChains" >
          <a routerLink="/chain/{{histChain?.certificateChainId}}">{{histChain?.timestamp|date:'medium'}}</a>
          <code>{{histChain.certificates[0].serialNumber}}</code>
          <div>Expires <time datetime='certificate.notAfter' title="{{histChain.certificates[0].notAfter | date:'long'}}">{{histChain.certificates[0].notAfter | date:'mediumDate'}}</time></div>
          <span *ngIf="histChain.success" class="badge badge-success">Success</span>
          <span *ngIf="!histChain.success" class="badge badge-warning">Warnings</span>
        </li>
      </ul>
    </div>
    <ng-template #noResultsMessage>(No results)</ng-template>
  </div>
  