import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateService } from '../../../app/services';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(certificateService, route, router) {
        this.certificateService = certificateService;
        this.route = route;
        this.router = router;
        this.hideResult = false;
        this.searching = false;
    }
    HomeComponent.prototype.ngOnInit = function () {
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.hostname = this.route.snapshot.queryParams.hostname;
        this.hideResult = String(this.route.snapshot.queryParams.hideResult) === 'true';
        if (this.hostname) {
            this.searching = true;
            this.getNewCertificateChain(this.hostname);
        }
    };
    HomeComponent.prototype.extractHostname = function (hostname) {
        hostname = hostname.trim();
        var regex = new RegExp('^(?:f|ht)tp(?:s)?\://([^/]+)', 'im');
        var regex2 = new RegExp('^((?:f|ht)tp(?:s)?\://)?([^/]+)', 'im');
        if (hostname.match(regex)) {
            return hostname.match(regex)[1].toString();
        }
        else if (hostname.match(regex2)) {
            return hostname.match(regex2)[2].toString();
        }
        return hostname;
    };
    HomeComponent.prototype.getNewCertificateChain = function (hostname) {
        var _this = this;
        this.hostname = this.extractHostname(hostname);
        this.searching = true;
        this.certificateService.getNewCertificateChain(this.hostname, this.hideResult).subscribe(function (response) {
            _this.chain = response;
            _this.searching = false;
        }, function () {
            _this.searching = false;
            _this.error = "Failed to inspect certificate for " + _this.hostname + ". Please try again.";
        });
    };
    HomeComponent.prototype.redirect = function () {
        this.error = null;
        this.hostname = this.extractHostname(this.hostname);
        this.router.navigate(['/home'], { queryParams: { hostname: this.hostname, hideResult: this.hideResult } });
    };
    return HomeComponent;
}());
export { HomeComponent };
