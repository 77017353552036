import { OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(auth) {
        this.auth = auth;
    }
    ProfileComponent.prototype.ngOnInit = function () {
    };
    return ProfileComponent;
}());
export { ProfileComponent };
