import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AppConfigService = /** @class */ (function () {
    function AppConfigService(httpClient) {
        this.httpClient = httpClient;
        this.config = null;
    }
    AppConfigService.prototype.load = function () {
        var _this = this;
        this.httpClient.get(environment.apiEndpoint + "/config").subscribe(function (response) {
            _this.config = response;
        });
    };
    AppConfigService.prototype.get = function (key) {
        if (this.config != null) {
            return this.config[key];
        }
    };
    AppConfigService.ngInjectableDef = i0.defineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.inject(i1.HttpClient)); }, token: AppConfigService, providedIn: "root" });
    return AppConfigService;
}());
export { AppConfigService };
