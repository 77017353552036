import { Routes } from '@angular/router';
import { AboutComponent, CallbackComponent, ChainComponent, CertificateViewerComponent, HistoryComponent, HomeComponent, ProfileComponent, ReportsComponent, SearchComponent, TrustedComponent, } from './components';
var routes = [
    { path: 'about', component: AboutComponent },
    { path: 'callback', component: CallbackComponent },
    { path: 'chain/:certificateChainId', component: ChainComponent },
    { path: 'certificate/:certificateId', component: CertificateViewerComponent },
    { path: 'home', component: HomeComponent },
    { path: ':hostname/history', component: HistoryComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'reports', component: ReportsComponent },
    { path: 'search', component: SearchComponent },
    { path: 'trusted', component: TrustedComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', redirectTo: '/home' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
