/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../certificate/certificate.component.ngfactory";
import * as i2 from "../certificate/certificate.component";
import * as i3 from "../../services/certificate.service";
import * as i4 from "@angular/router";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@angular/common";
import * as i7 from "./certificate-viewer.component";
var styles_CertificateViewerComponent = [];
var RenderType_CertificateViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CertificateViewerComponent, data: {} });
export { RenderType_CertificateViewerComponent as RenderType_CertificateViewerComponent };
function View_CertificateViewerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-certificate", [], null, null, null, i1.View_CertificateComponent_0, i1.RenderType_CertificateComponent)), i0.ɵdid(1, 114688, null, 0, i2.CertificateComponent, [i3.CertificateService, i4.Router], { certificate: [0, "certificate"], index: [1, "index"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.certificate; var currVal_1 = _co.i; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CertificateViewerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["class", "d-inline-block"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(2, 737280, null, 3, i5.NgbDropdown, [i0.ChangeDetectorRef, i5.NgbDropdownConfig, i6.DOCUMENT, i0.NgZone, i0.ElementRef, i0.Renderer2, [2, i5.ɵm]], null, null), i0.ɵqud(335544320, 1, { _menu: 0 }), i0.ɵqud(335544320, 2, { _menuElement: 0 }), i0.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-outline-primary dropdown-toggle"], ["id", "dropdownBasic1"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i5.NgbDropdownToggle, [i5.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[3, 4]], i5.NgbDropdownAnchor, null, [i5.NgbDropdownToggle]), (_l()(), i0.ɵted(-1, null, ["Actions"])), (_l()(), i0.ɵeld(10, 0, [[2, 0]], null, 8, "div", [["aria-labelledby", "dropdownBasic1"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, [[1, 4]], 1, i5.NgbDropdownMenu, [i5.NgbDropdown], null, null), i0.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""], ["title", "Save certificate"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveCertificate(_co.certificate.sha256) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 16384, [[4, 4]], 0, i5.NgbDropdownItem, [i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, null, ["Save Certificate"])), (_l()(), i0.ɵeld(16, 0, null, null, 2, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""], ["title", "Save certificate chain"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveCertificateChain(_co.certificate.sha256) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(17, 16384, [[4, 4]], 0, i5.NgbDropdownItem, [i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, null, ["Save Certificate Chain"])), (_l()(), i0.ɵeld(19, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["View Certificate"])), (_l()(), i0.ɵeld(21, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(22, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificateViewerComponent_1)), i0.ɵdid(24, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_8 = _co.certificate; _ck(_v, 24, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 7).dropdown.isOpen(); _ck(_v, 6, 0, currVal_1); var currVal_2 = true; var currVal_3 = i0.ɵnov(_v, 11).dropdown.isOpen(); var currVal_4 = i0.ɵnov(_v, 11).placement; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i0.ɵnov(_v, 14).disabled; _ck(_v, 13, 0, currVal_5); var currVal_6 = i0.ɵnov(_v, 17).disabled; _ck(_v, 16, 0, currVal_6); var currVal_7 = ((_co.certificate == null) ? null : _co.certificate.commonName); _ck(_v, 22, 0, currVal_7); }); }
export function View_CertificateViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-certificate-viewer", [], null, null, null, View_CertificateViewerComponent_0, RenderType_CertificateViewerComponent)), i0.ɵdid(1, 114688, null, 0, i7.CertificateViewerComponent, [i3.CertificateService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CertificateViewerComponentNgFactory = i0.ɵccf("app-certificate-viewer", i7.CertificateViewerComponent, View_CertificateViewerComponent_Host_0, {}, {}, []);
export { CertificateViewerComponentNgFactory as CertificateViewerComponentNgFactory };
