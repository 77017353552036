<h2>Certificate Checker</h2>

<p class="lead">
Configuring certificate and certificate chains for secure communications can be challenging.
This is true for static-content web servers, Java-based REST micro-services, and numerous 
applications that leverage TLS for secure client communications. Applications include reverse
proxies, Jetty and Tomcat, Apache Kafka, Solr and Elasticsearch, Hadoop clusters, Splunk, 
Nexus or Artifactory repositories, Jenkins, load balancers, and many, many more. Configuring
clients of these services can often also be complicated because it is difficult to understand
what certificates the service presents and which certificates should be added to the client's
trust store (if the service uses a CA that isn't included in the OS, JRE, or runtime platform).
</p>
<p class="lead">
Certificate Checker provides an easy-to-use solution to check certificates, certificate chains,
and TLS configurations. To run Certificate Checker for publicly-accessible web sites you can go
to: https://certchecker.app and enter in there a URL to check.  You can also run Certificate
Checker on an internal network to validate or troubleshoot non-public-facing TLS configurations.
</p>