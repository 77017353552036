import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CertificateService = /** @class */ (function () {
    function CertificateService(http) {
        this.http = http;
    }
    CertificateService.prototype.getCertificate = function (certificateId) {
        return this.http.get(environment.apiEndpoint + "/certificates/" + certificateId);
    };
    CertificateService.prototype.getTrustedCertificates = function () {
        return this.http.get(environment.apiEndpoint + "/certificates/trusted");
    };
    CertificateService.prototype.getSimilarCertificates = function (sha256) {
        return this.http.get(environment.apiEndpoint + "/certificates/" + sha256 + "/similar");
    };
    CertificateService.prototype.getChainsContaining = function (sha256) {
        return this.http.get(environment.apiEndpoint + "/chains/includes/" + sha256);
    };
    CertificateService.prototype.getNewCertificateChain = function (hostname, hideResult) {
        if (hideResult === void 0) { hideResult = false; }
        var params = new HttpParams()
            .append('hostname', hostname)
            .append('hideResult', hideResult.toString());
        return this.http.post(environment.apiEndpoint + "/chains", {}, { params: params });
    };
    CertificateService.prototype.getCertificateChain = function (certificateChainId) {
        return this.http.get(environment.apiEndpoint + "/chains/" + certificateChainId);
    };
    CertificateService.prototype.getRecentChains = function (page) {
        return this.http.get(environment.apiEndpoint + "/chains/recent?page=" + page);
    };
    CertificateService.prototype.getCertificateChainsByHostname = function (hostname) {
        return this.http.get(environment.apiEndpoint + "/chains/historical/" + hostname);
    };
    CertificateService.ngInjectableDef = i0.defineInjectable({ factory: function CertificateService_Factory() { return new CertificateService(i0.inject(i1.HttpClient)); }, token: CertificateService, providedIn: "root" });
    return CertificateService;
}());
export { CertificateService };
