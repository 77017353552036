/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../certificate-chain/certificate-chain.component.ngfactory";
import * as i4 from "../certificate-chain/certificate-chain.component";
import * as i5 from "./chain.component";
import * as i6 from "../../services/certificate.service";
var styles_ChainComponent = [];
var RenderType_ChainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChainComponent, data: {} });
export { RenderType_ChainComponent as RenderType_ChainComponent };
function View_ChainComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-outline-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["History"])), (_l()(), i0.ɵted(-1, null, ["\u00A0 "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-outline-primary"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reinspect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Reinspect"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "/", _co.chain.hostname, "/history"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChainComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Warning!"])), (_l()(), i0.ɵted(3, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 3, 0, currVal_0); }); }
export function View_ChainComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChainComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChainComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Certificate Chain"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-certificate-chain", [], null, null, null, i3.View_CertificateChainComponent_0, i3.RenderType_CertificateChainComponent)), i0.ɵdid(7, 114688, null, 0, i4.CertificateChainComponent, [], { chain: [0, "chain"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chain; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.chain; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ChainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-chain", [], null, null, null, View_ChainComponent_0, RenderType_ChainComponent)), i0.ɵdid(1, 114688, null, 0, i5.ChainComponent, [i6.CertificateService, i1.ActivatedRoute, i1.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChainComponentNgFactory = i0.ɵccf("app-chain", i5.ChainComponent, View_ChainComponent_Host_0, {}, {}, []);
export { ChainComponentNgFactory as ChainComponentNgFactory };
