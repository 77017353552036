/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./reports.component";
var styles_ReportsComponent = [];
var RenderType_ReportsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReportsComponent, data: {} });
export { RenderType_ReportsComponent as RenderType_ReportsComponent };
export function View_ReportsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Reports"]))], null, null); }
export function View_ReportsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-reports", [], null, null, null, View_ReportsComponent_0, RenderType_ReportsComponent)), i0.ɵdid(1, 114688, null, 0, i1.ReportsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportsComponentNgFactory = i0.ɵccf("app-reports", i1.ReportsComponent, View_ReportsComponent_Host_0, {}, {}, []);
export { ReportsComponentNgFactory as ReportsComponentNgFactory };
