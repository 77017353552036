/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../certificate/certificate.component.ngfactory";
import * as i3 from "../certificate/certificate.component";
import * as i4 from "../../services/certificate.service";
import * as i5 from "@angular/router";
import * as i6 from "./certificate-chain.component";
var styles_CertificateChainComponent = [];
var RenderType_CertificateChainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CertificateChainComponent, data: {} });
export { RenderType_CertificateChainComponent as RenderType_CertificateChainComponent };
function View_CertificateChainComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "text-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["Inspected ", " at ", ""])), i0.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chain.hostname; var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), ((_co.chain == null) ? null : _co.chain.timestamp), "medium")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CertificateChainComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["- ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_CertificateChainComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-warning"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Warning!"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificateChainComponent_3)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.chain == null) ? null : _co.chain.warnings); _ck(_v, 4, 0, currVal_0); }, null); }
function View_CertificateChainComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "alert alert-warning"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Warning!"])), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.chain == null) ? null : _co.chain.warnings[0]); _ck(_v, 3, 0, currVal_0); }); }
function View_CertificateChainComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-dismissible alert-success"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" The certificate chain for "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(4, null, [" is properly configured - yay! It contains the server certificate and ", " additional certificates. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.chain == null) ? null : _co.chain.hostname); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_co.chain == null) ? null : _co.chain.certificates.length) - 1); _ck(_v, 4, 0, currVal_1); }); }
function View_CertificateChainComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], [[1, "data-index", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-certificate", [], null, null, null, i2.View_CertificateComponent_0, i2.RenderType_CertificateComponent)), i0.ɵdid(2, 114688, null, 0, i3.CertificateComponent, [i4.CertificateService, i5.Router], { certificate: [0, "certificate"], index: [1, "index"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; var currVal_2 = _v.context.index; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.index; _ck(_v, 0, 0, currVal_0); }); }
export function View_CertificateChainComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificateChainComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificateChainComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificateChainComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificateChainComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificateChainComponent_6)), i0.ɵdid(11, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chain; _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_co.chain == null) ? null : ((_co.chain.warnings == null) ? null : _co.chain.warnings.length)) > 1); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_co.chain == null) ? null : ((_co.chain.warnings == null) ? null : _co.chain.warnings.length)) == 1); _ck(_v, 7, 0, currVal_2); var tmp_3_0 = null; var currVal_3 = ((_co.chain && (((_co.chain == null) ? null : ((_co.chain.warnings == null) ? null : _co.chain.warnings.length)) == 0)) && ((((tmp_3_0 = _co.getWarnings(((_co.chain == null) ? null : _co.chain.certificates))) == null) ? null : tmp_3_0.length) == 0)); _ck(_v, 9, 0, currVal_3); var currVal_4 = ((_co.chain == null) ? null : _co.chain.certificates); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_CertificateChainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-certificate-chain", [], null, null, null, View_CertificateChainComponent_0, RenderType_CertificateChainComponent)), i0.ɵdid(1, 114688, null, 0, i6.CertificateChainComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CertificateChainComponentNgFactory = i0.ɵccf("app-certificate-chain", i6.CertificateChainComponent, View_CertificateChainComponent_Host_0, { chain: "chain" }, {}, []);
export { CertificateChainComponentNgFactory as CertificateChainComponentNgFactory };
