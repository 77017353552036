import { OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { CertificateService } from '../../../app/services';
var CertificateViewerComponent = /** @class */ (function () {
    function CertificateViewerComponent(certificateService, route) {
        this.certificateService = certificateService;
        this.route = route;
    }
    CertificateViewerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var certificateId = this.route.snapshot.paramMap.get('certificateId');
        if (certificateId) {
            this.certificateService.getCertificate(certificateId).subscribe(function (certificate) {
                _this.certificate = certificate;
            });
        }
    };
    CertificateViewerComponent.prototype.saveCertificate = function (sha256) {
        var url = environment.apiEndpoint + "/certificates/" + sha256 + "/raw";
        window.open(url);
    };
    CertificateViewerComponent.prototype.saveCertificateChain = function (sha256) {
        var url = environment.apiEndpoint + "/certificates/" + sha256 + "/chain";
        window.open(url);
    };
    return CertificateViewerComponent;
}());
export { CertificateViewerComponent };
