import { OnInit } from '@angular/core';
var CertificateChainComponent = /** @class */ (function () {
    function CertificateChainComponent() {
    }
    CertificateChainComponent.prototype.ngOnInit = function () { };
    CertificateChainComponent.prototype.getWarnings = function (certificates) {
        return certificates.map(function (certificate) { return certificate.warnings; }).reduce(function (list, value) { return list.concat(value); }, []);
    };
    return CertificateChainComponent;
}());
export { CertificateChainComponent };
