import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CertificateService } from '../../../app/services';
var CertificateComponent = /** @class */ (function () {
    function CertificateComponent(certificateService, router) {
        this.certificateService = certificateService;
        this.router = router;
        this.collapsed = true;
    }
    CertificateComponent.prototype.ngOnInit = function () {
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        if (this.index === undefined) {
            this.getSimilar(this.certificate.sha256);
            this.getChainsContaining(this.certificate.sha256);
        }
    };
    CertificateComponent.prototype.isExpired = function (date) {
        return Date.parse(date) < Date.now();
    };
    CertificateComponent.prototype.getSimilar = function (sha256) {
        var _this = this;
        this.certificateService.getSimilarCertificates(sha256).subscribe(function (response) { return _this.similar = response; });
    };
    CertificateComponent.prototype.getChainsContaining = function (sha256) {
        var _this = this;
        this.certificateService.getChainsContaining(sha256).subscribe(function (response) { return _this.chains = response; });
    };
    return CertificateComponent;
}());
export { CertificateComponent };
