<div class="float-right">
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="saveCertificate(certificate.sha256)" title="Save certificate">Save
          Certificate</button>
        <button ngbDropdownItem (click)="saveCertificateChain(certificate.sha256)"
          title="Save certificate chain">Save Certificate Chain</button>
      </div>
    </div>
  </div>
  
<h2>View Certificate</h2>

<h4>{{certificate?.commonName}}</h4>
<app-certificate *ngIf="certificate" [certificate]="certificate" [index]="i"></app-certificate>