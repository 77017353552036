<div *ngIf="recentChains">
<h5>Recently Inspected Endpoints</h5>
<ul class="list-group">
  <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let chain of recentChains" >
    <a routerLink="/chain/{{chain.certificateChainId}}">{{chain.hostname}}</a>
    <span *ngIf="chain.success" class="badge badge-success">Success</span>
    <span *ngIf="!chain.success" class="badge badge-warning">Warnings</span>
  </li>
</ul>
</div>

<div class="d-flex justify-content-center" *ngIf="!recentChains">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
