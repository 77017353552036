<h2>My Profile</h2>


<ul class="list-group">
  <li class="list-group-item d-flex justify-content-between align-items-center">
      <div><strong>Name</strong>: {{auth.userProfile?.name}}</div>
  </li>
  <li class="list-group-item d-flex justify-content-between align-items-center">
    <div><strong>Email</strong>: {{auth.userProfile?.email}}</div>
  </li>
</ul>