import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SearchService = /** @class */ (function () {
    function SearchService(http) {
        this.http = http;
    }
    SearchService.prototype.searchCertificates = function (filters) {
        return this.http.get("/api/certificates?filters=" + filters);
    };
    SearchService.ngInjectableDef = i0.defineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.inject(i1.HttpClient)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
