import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateService } from '../../../app/services';
var HistoryComponent = /** @class */ (function () {
    function HistoryComponent(certificateService, route, router) {
        this.certificateService = certificateService;
        this.route = route;
        this.router = router;
    }
    HistoryComponent.prototype.ngOnInit = function () {
        var hostname = this.route.snapshot.paramMap.get('hostname');
        this.hostname = hostname;
        this.showHistory(hostname);
    };
    HistoryComponent.prototype.showHistory = function (hostname) {
        var _this = this;
        this.certificateService.getCertificateChainsByHostname(hostname).subscribe(function (response) {
            _this.historicalChains = response;
        }, function () {
            console.log("Failed to find chain history for " + hostname + ". Please try again.");
        });
    };
    return HistoryComponent;
}());
export { HistoryComponent };
