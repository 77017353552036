import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateService } from '../../../app/services';
var ChainComponent = /** @class */ (function () {
    function ChainComponent(certificateService, route, router) {
        this.certificateService = certificateService;
        this.route = route;
        this.router = router;
    }
    ChainComponent.prototype.ngOnInit = function () {
        var _this = this;
        var certificateChainId = this.route.snapshot.paramMap.get('certificateChainId');
        if (certificateChainId) {
            this.certificateService.getCertificateChain(certificateChainId).subscribe(function (response) {
                _this.chain = response;
            });
        }
    };
    ChainComponent.prototype.reinspect = function () {
        this.getNewCertificateChain(this.chain.hostname);
        this.error = null;
    };
    ChainComponent.prototype.getNewCertificateChain = function (hostname) {
        var _this = this;
        this.certificateService.getNewCertificateChain(hostname).subscribe(function (response) {
            _this.chain = response;
            _this.router.navigate(["/chain/" + _this.chain.certificateChainId]);
        }, function () {
            _this.error = "Failed to inspect certificate for " + hostname + ". Please try again.";
        });
    };
    return ChainComponent;
}());
export { ChainComponent };
