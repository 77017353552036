import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(document, http, router) {
        this.document = document;
        this.http = http;
        this.router = router;
        this.loadProfile();
    }
    AuthService.prototype.login = function () {
        console.log('logginging in');
        document.location.href = '/login';
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        console.log('logginging out');
        this.http.get('/api/logout').subscribe(function () {
            _this.userProfile = undefined;
            _this.router.navigate(['/home']);
        });
    };
    AuthService.prototype.isAuthenticated = function () {
        return this.userProfile;
    };
    AuthService.prototype.getProfile = function () {
        return this.userProfile;
    };
    AuthService.prototype.loadProfile = function () {
        var _this = this;
        this.http.get('/api/user').subscribe(function (user) {
            console.log('got', user);
            _this.userProfile = user;
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.DOCUMENT), i0.inject(i2.HttpClient), i0.inject(i3.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
