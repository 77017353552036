import { OnInit } from '@angular/core';
import { CertificateService } from '../../../app/services';
var TrustedComponent = /** @class */ (function () {
    function TrustedComponent(certificateService) {
        this.certificateService = certificateService;
    }
    TrustedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.certificateService.getTrustedCertificates().subscribe(function (results) { return _this.trusted = results; });
    };
    TrustedComponent.prototype.isExpired = function (date) {
        return Date.parse(date) < Date.now();
    };
    return TrustedComponent;
}());
export { TrustedComponent };
