import { OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppConfigService } from '../../services';
var AppNavbarComponent = /** @class */ (function () {
    function AppNavbarComponent(authService, appConfigService) {
        this.authService = authService;
        this.collapsed = true;
        this.auth = authService;
        this.appConfigService = appConfigService;
    }
    AppNavbarComponent.prototype.ngOnInit = function () {
    };
    AppNavbarComponent.prototype.login = function () {
        this.auth.login();
    };
    return AppNavbarComponent;
}());
export { AppNavbarComponent };
