import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../services/search.service';
var SearchComponent = /** @class */ (function () {
    function SearchComponent(searchService, route, router) {
        this.searchService = searchService;
        this.route = route;
        this.router = router;
        this.filters = {};
    }
    SearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        var filters = this.route.snapshot.queryParams.filters;
        if (filters) {
            this.searchService.searchCertificates(filters).subscribe(function (certificates) {
                _this.certificates = certificates;
            });
        }
    };
    SearchComponent.prototype.search = function () {
        var _this = this;
        var string = this.mapToString(this.filters);
        this.searchService.searchCertificates(string).subscribe(function (certificates) {
            _this.router.navigate(['/search'], { queryParams: { filters: string } });
            _this.certificates = certificates;
        });
    };
    SearchComponent.prototype.mapToString = function (filters) {
        var string = '';
        Object.entries(filters).forEach(function (entry) {
            var key = entry[0];
            var value = entry[1];
            if (key && value) {
                string = string.concat(key + ":" + value + ",");
            }
        });
        return string;
    };
    return SearchComponent;
}());
export { SearchComponent };
